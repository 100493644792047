import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Alert, Snackbar, CircularProgress } from "@mui/material";
import ClientDashboard from "./clientDashboard";
import AdminPanel from "./adminPanel";
import StaffPanel from "./staffPanel";
import axios from "../../common/axiosPrivate";

const styles = {
  cntnr: {
    minHeight: "100vh",
  },
};

export default function DashboardPage(props) {
  const [isLoggedIn, setIsLoggedIn] = useState("loading");
  const [userType, setUserType] = useState("loading");
  const [muiAlert, setMuiAlert] = useState({
    show: false,
    type: "",
    message: "",
  });

  const navigate = useNavigate();

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMuiAlert((prevState) => {
      return { ...prevState, show: false };
    });
  };

  const checkUserType = async () => {
    await axios
      .get("/accounts/check_account_type/")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.user_type === "superuser") {
            setUserType("superuser");
          } else if (res.data.data.user_type === "staff") {
            setUserType("staff");
          } else {
            setUserType("client");
          }
        }
      })
      .catch((err) => {
        setMuiAlert({
          show: true,
          type: "error",
          message: "مشکلی به وجود آمده، لطفا دوباره امتحان کنید",
        });
      });
  };

  const handleCheckLoggedIn = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      await axios
        .get("/accounts/token/verify/", {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          if (res.status === 200) {
            setIsLoggedIn(true);
            checkUserType();
          }
        })
        .catch((err) => {
          setMuiAlert({
            show: true,
            type: "error",
            message: "لطفا وارد حساب خود شوید",
          });
          setIsLoggedIn(false);
          navigate("/register");
        });
    } else {
      setMuiAlert({
        show: true,
        type: "error",
        message: "لطفا وارد حساب خود شوید",
      });
      setIsLoggedIn(false);
      navigate("/register");
    }
  };

  useEffect(() => {
    handleCheckLoggedIn();
  }, []);

  return (
    <Grid2
      container
      xs={12}
      justifyContent={"center"}
      alignContent={"center"}
      sx={styles.cntnr}
    >
      {isLoggedIn === "loading" || userType === "loading" ? (
        <CircularProgress />
      ) : (
        isLoggedIn &&
        (userType === "client" ? (
          <ClientDashboard />
        ) : userType === "staff" ? (
          <StaffPanel />
        ) : (
          userType === "superuser" && <AdminPanel />
        ))
      )}
      <Snackbar
        open={muiAlert.show}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseAlert} severity={muiAlert.type}>
          {muiAlert.message}
        </Alert>
      </Snackbar>
    </Grid2>
  );
}
