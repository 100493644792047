/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalali";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import {
  Button,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import axiosPublic from "../../common/axiosPublic";
import axios from "../../common/axiosPrivate";
import {
  gregorianToJalaliDate,
  gregorianToJalaliDateTime,
  numericDateToAlphebet,
} from "../../statics/CONST";

const styles = {
  cntnr: {
    minHeight: "calc(100vh - 14.85rem)",
    mt: "2rem",
    color: "#014347",
  },
  widthLmt: {
    maxWidth: "62.5rem",
    m: "1rem",
  },
  contentRowsCntnr: {
    mb: "1rem",
  },
  partCntnr: {
    mb: "3rem",
  },
  title: {
    fontSize: "2rem",
    fontWeight: 750,
  },
  icon: {
    fontSize: "2rem",
    mr: "0.5rem",
  },
  menuItems: {
    color: "#014347",
  },
  link: css`
    text-decoration: none;
    color: #014347;
  `,
  formCntnr: {
    mt: "1rem",
  },
  textFld: {
    width: "100%",
  },
  buttonCntnr: {
    mt: { xs: "1rem", md: "1.5rem" },
  },
  button: {
    width: { xs: "100%", md: "15rem" },
    color: "#fff",
  },
  r1_iframe_embed: css`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    padding-top: 56.25%;
  `,
  r1_iframe_embed_iframe: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border: 0 #ffffff none;
  `,
  tableHeadCell: {
    fontWeight: 600,
    fontSize: "1.1rem",
    padding: "12px",
  },
  tableCell: { padding: "12px" },
};

const streamList = {
  fullbody: (
    <>
      <Grid2
        xs={12}
        sx={{
          fontWeight: 700,
          fontSize: "1.25rem",
          mt: "1.5rem",
          mb: "0.5rem",
        }}
      >
        دوره فول بادی
      </Grid2>

      <div css={styles.r1_iframe_embed}>
        <iframe
          src="https://player.arvancloud.ir/index.html?config=https://doctormehrdad.arvanlive.ir/fullbody/origin_config.json&skin=shaka"
          css={styles.r1_iframe_embed_iframe}
          name="دوره فول بادی"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        ></iframe>
      </div>
    </>
  ),
  aerobic: (
    <>
      <Grid2
        xs={12}
        sx={{
          fontWeight: 700,
          fontSize: "1.25rem",
          mt: "2rem",
          mb: "0.5rem",
        }}
      >
        دوره هوازی
      </Grid2>
      <div css={styles.r1_iframe_embed}>
        <iframe
          src="https://player.arvancloud.ir/index.html?config=https://doctormehrdad.arvanlive.ir/cardio/origin_config.json&skin=shaka"
          css={styles.r1_iframe_embed_iframe}
          name="دوره هوازی"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        ></iframe>
      </div>
    </>
  ),
};

export default function StaffPanel() {
  const [values, setValues] = useState({
    // new live data
    latestPacks: [],
    liveSelectedPack: "",
    liveDate: null,
    liveUrl: "",
  });
  const [data, setData] = useState({
    lives_archive_info: [],
  });

  const [liveFields, setLiveFields] = useState([
    { title: "نوع دوره", type: "select", enTitle: "liveSelectedPack" },
    { title: "تاریخ جلسه", type: "date", enTitle: "liveDate" },
    { title: "لینک لایو", type: "text", enTitle: "liveUrl" },
  ]);

  const [muiAlert, setMuiAlert] = useState({
    show: false,
    type: "",
    message: "",
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMuiAlert((prevState) => {
      return { ...prevState, show: false };
    });
  };

  const handleChange = (parameter, type) => (event) => {
    if (type === "date") {
      let fullDate =
        event.getFullYear() + "-" + event.getMonth() + "-" + event.getDate();
      setValues((prevState) => {
        return {
          ...prevState,
          [parameter]: event,
          [parameter + "Clean"]: fullDate,
        };
      });
    } else if (type === "dateTime") {
      let fullDate =
        event.getFullYear() +
        "-" +
        event.getMonth() +
        "-" +
        event.getDate() +
        " " +
        event.getHours() +
        ":" +
        event.getMinutes() +
        ":" +
        event.getSeconds();
      setValues((prevState) => {
        return {
          ...prevState,
          [parameter]: event,
          [parameter + "Clean"]: fullDate,
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [parameter]: event.target.value,
        };
      });
    }
  };

  const handleFetchLatestPacks = async () => {
    await axiosPublic("packs/active_pack_essential/")
      .then((res) => {
        if (res.status === 200) {
          setValues((prevState) => {
            return { ...prevState, latestPacks: res.data.data };
          });
        }
      })
      .catch((err) => {});
  };

  const handleAddLiveUrl = async () => {
    let data = {
      package: values.liveSelectedPack,
      date: gregorianToJalaliDate(values.liveDate),
      url: values.liveUrl,
    };

    await axios
      .post("packs/live/", data)
      .then((res) => {
        if (res.status === 201) {
          setValues((prevState) => {
            return {
              ...prevState,
              liveSelectedPack: "",
              liveDate: null,
              liveUrl: "",
            };
          });
          setMuiAlert({
            show: true,
            type: "success",
            message: "لایو جدید اضافه شد",
          });
        }
      })
      .catch((err) => {
        setMuiAlert({
          show: true,
          type: "error",
          message: "مشکلی وجود دارد",
        });
      });
  };

  const handleLogout = () => {
    setAnchorEl(null);

    axios
      .post("/accounts/logout/")
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("token");
          navigate("/");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          navigate("/");
        }
      });
  };

  const handleFetchLiveData = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      await axios
        .get("/packs/live/", {})
        .then((res) => {
          if (res.status === 200) {
            setData((prevState) => {
              return {
                ...prevState,
                lives_archive_info: res.data.data,
              };
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/register");
          }
        });
    } else {
      navigate("/register");
    }
  };

  useEffect(() => {
    handleFetchLatestPacks();
    handleFetchLiveData();
  }, []);

  return (
    <Grid2 container xs={12} justifyContent={"center"} sx={styles.cntnr}>
      <Grid2 container xs={12} sx={styles.widthLmt}>
        <Grid2 container xs={12} justifyContent={"flex-end"}>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClickMenu}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Link to="/" css={styles.link}>
              <MenuItem onClick={handleCloseMenu}>
                <ListItemIcon>
                  <HomeIcon fontSize="small" sx={styles.menuItems} />
                </ListItemIcon>
                <ListItemText>صفحه‌ی اصلی</ListItemText>
              </MenuItem>
            </Link>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" sx={styles.menuItems} />
              </ListItemIcon>
              <ListItemText sx={styles.menuItems}>خروج</ListItemText>
            </MenuItem>
          </Menu>
        </Grid2>
        <Grid2 container xs={12} sx={[styles.partCntnr, { rowGap: 1 }]}>
          <Grid2 container xs={12} alignItems={"center"} sx={styles.title}>
            مشاهده لایو دوره‌ها
          </Grid2>

          {streamList.fullbody}
          {streamList.aerobic}
        </Grid2>
        <Grid2 container xs={12} sx={styles.partCntnr}>
          <Grid2
            container
            alignContent={"center"}
            xs={12}
            sx={styles.contentRowsCntnr}
          >
            <Grid2 container alignContent={"center"}>
              <CategoryOutlinedIcon sx={styles.icon} />
            </Grid2>
            <Grid2 sx={styles.title}> بروزرسانی آرشیو لایوها</Grid2>
          </Grid2>
          <Grid2
            container
            alignContent={"center"}
            xs={12}
            sx={styles.contentRowsCntnr}
          >
            برای افزودن لینک ویدیو لایو جلسه جدید، از این قسمت اقدام کنید.
          </Grid2>
          <Grid2
            container
            xs={12}
            spacing={{ xs: 2, md: 3 }}
            alignItems="flex-start"
            sx={styles.formCntnr}
          >
            {liveFields.map((item) => {
              return (
                <Grid2 xs={12} md={item.type === "text" ? 12 : 6}>
                  {item.type === "text" ? (
                    <TextField
                      variant="outlined"
                      multiline
                      label={item.title}
                      placeholder={item.placeHolder && item.placeHolder}
                      value={values[item.enTitle]}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {item.adornment}
                          </InputAdornment>
                        ),
                      }}
                      sx={styles.textFld}
                      onChange={handleChange(item.enTitle, item.type)}
                    />
                  ) : item.type === "date" ? (
                    <LocalizationProvider
                      dateAdapter={AdapterDateFnsJalali}
                      localeText={{
                        cancelButtonLabel: "خروج",
                        okButtonLabel: "ذخیره",
                      }}
                    >
                      <MobileDatePicker
                        label={item.title}
                        value={values[item.enTitle]}
                        sx={styles.textFld}
                        onChange={handleChange(item.enTitle, item.type)}
                      />
                    </LocalizationProvider>
                  ) : (
                    item.type === "select" && (
                      <TextField
                        select
                        label={item.title}
                        value={values[item.enTitle]}
                        sx={styles.textFld}
                        onChange={handleChange(item.enTitle, item.type)}
                      >
                        {values.latestPacks.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  )}
                </Grid2>
              );
            })}
          </Grid2>
          <Grid2
            container
            xs={12}
            justifyContent={"flex-end"}
            sx={styles.buttonCntnr}
          >
            <Button
              variant="contained"
              sx={styles.button}
              onClick={handleAddLiveUrl}
            >
              افزودن لایو
            </Button>
          </Grid2>
        </Grid2>
        <Grid2 container xs={12} sx={{ rowGap: 1, mt: "2rem" }}>
          <Grid2 container xs={12} alignItems={"center"} sx={styles.title}>
            آرشیو لایوها
          </Grid2>
          <Grid2 xs={12} sx={{}}>
            لایو جلسات چند ساعت پس از اتمام جلسه ذخیره خواهد شد و می‌توانید از
            این قسمت آن را مشاهده کنید.
          </Grid2>

          <Grid2 container xs={12} justifyContent={"center"} sx={{ mt: 3 }}>
            <TableContainer component={Paper} sx={{ maxWidth: 650 }}>
              <Table sx={{ maxWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#dcdcdc" }}>
                    <TableCell sx={styles.tableHeadCell}>دوره</TableCell>
                    <TableCell sx={styles.tableHeadCell}>تاریخ</TableCell>
                    <TableCell sx={styles.tableHeadCell} align="right">
                      لینک ویدیو
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.lives_archive_info.map((row) => {
                    let alphDate = numericDateToAlphebet(row.date);
                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={styles.tableCell}
                        >
                          {row.category}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {alphDate.day +
                            " " +
                            alphDate.month +
                            " " +
                            alphDate.year}
                        </TableCell>
                        <TableCell sx={styles.tableCell} align="right">
                          <a
                            href={row.url}
                            css={styles.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            مشاهده ویدیو
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid2>
        </Grid2>
      </Grid2>
      <Snackbar
        open={muiAlert.show}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseAlert} severity={muiAlert.type}>
          {muiAlert.message}
        </Alert>
      </Snackbar>
    </Grid2>
  );
}
