/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Grid2 from "@mui/material/Unstable_Grid2";
import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalali";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import {
  Button,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PeopleIcon from "@mui/icons-material/People";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import axiosPublic from "../../common/axiosPublic";
import axios from "../../common/axiosPrivate";
import {
  gregorianToJalaliDate,
  gregorianToJalaliDateTime,
} from "../../statics/CONST";

const styles = {
  cntnr: {
    minHeight: "calc(100vh - 14.85rem)",
    mt: "2rem",
    color: "#014347",
  },
  widthLmt: {
    maxWidth: "62.5rem",
    m: "1rem",
  },
  contentRowsCntnr: {
    mb: "1rem",
  },
  partCntnr: {
    mb: "3rem",
  },
  title: {
    fontSize: "2rem",
    fontWeight: 750,
  },
  icon: {
    fontSize: "2rem",
    mr: "0.5rem",
  },
  menuItems: {
    color: "#014347",
  },
  link: css`
    text-decoration: none;
    color: #014347;
  `,
  formCntnr: {
    mt: "1rem",
  },
  textFld: {
    width: "100%",
  },
  buttonCntnr: {
    mt: { xs: "1rem", md: "1.5rem" },
  },
  button: {
    width: { xs: "100%", md: "15rem" },
    color: "#fff",
  },
  reportsTitles: {
    fontWeight: 600,
    fontSize: "1.25rem",
  },
  reportBtn: {
    width: "100%",
    color: "#fff",
  },
};

export default function AdminPanel() {
  const [values, setValues] = useState({
    packTypes: [
      { enName: "fullbody", faName: "فول‌بادی" },
      { enName: "aerobic", faName: "هوازی" },
    ],

    // reports data
    paymentStartDate: null,
    paymentEndDate: null,
    newUsersStartDate: null,
    newUsersEndDate: null,
    expiredUsersPackage: "",
    instaIdUpdateStartDate: null,
    instaIdUpdateEndDate: null,
    paymentsData: {},
    newUserData: [],
    expiredUserData: [],

    // new pack data
    packSelectedPackType: "",
    packType: "",
    packName: "",
    packEnName: "",
    packStartDate: null,
    packStartDateClean: "",
    packEndDate: null,
    packEndDateClean: "",
    packRegisterStartDate: null,
    packRegisterStartDateClean: "",
    packRegisterEndDate: null,
    packRegisterEndDateClean: "",
    packRechargeStartDate: null,
    packRechargeStartDateClean: "",
    packPrice: "",
    instagramId: "",

    // new category data
    catName: "",
    catEnName: "",
    catGoal: "",
    catEquipments: "",

    // new live data
    latestPacks: [],
    liveSelectedPack: "",
    liveDate: null,
    liveUrl: "",
  });

  const [newPackageFields, setNewPackageFields] = useState([
    { title: "نوع دوره", type: "select", enTitle: "packSelectedPackType" },
    { title: "نام فارسی", type: "text", enTitle: "packName" },
    { title: "نام انگلیسی", type: "text", enTitle: "packEnName" },
    { title: "تاریخ شروع", type: "date", enTitle: "packStartDate" },
    { title: "تاریخ پایان", type: "date", enTitle: "packEndDate" },
    {
      title: "زمان شروع تمدید",
      type: "dateTime",
      enTitle: "packRechargeStartDate",
    },
    {
      title: "زمان شروع ثبت نام",
      type: "dateTime",
      enTitle: "packRegisterStartDate",
    },
    {
      title: "زمان پایان ثبت نام",
      type: "dateTime",
      enTitle: "packRegisterEndDate",
    },
    {
      title: "قیمت",
      type: "text",
      enTitle: "packPrice",
      placeHolder: "به ریال",
      adornment: "ریال",
    },
    { title: "آیدی اینستاگرام", type: "text", enTitle: "instagramId" },
  ]);

  const [newCategoryFields, setNewCategoryFields] = useState([
    { title: "نام", enTitle: "catName" },
    { title: "نام انگلیسی", enTitle: "catEnName" },
    { title: "هدف", enTitle: "catGoal" },
    { title: "تجهیزات", enTitle: "catEquipments" },
  ]);

  const [liveFields, setLiveFields] = useState([
    { title: "نوع دوره", type: "select", enTitle: "liveSelectedPack" },
    { title: "تاریخ جلسه", type: "date", enTitle: "liveDate" },
    { title: "لینک لایو", type: "text", enTitle: "liveUrl" },
  ]);

  const [muiAlert, setMuiAlert] = useState({
    show: false,
    type: "",
    message: "",
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMuiAlert((prevState) => {
      return { ...prevState, show: false };
    });
  };

  const handleChange = (parameter, type) => (event) => {
    if (type === "date") {
      let fullDate =
        event.getFullYear() + "-" + event.getMonth() + "-" + event.getDate();
      setValues((prevState) => {
        return {
          ...prevState,
          [parameter]: event,
          [parameter + "Clean"]: fullDate,
        };
      });
    } else if (type === "dateTime") {
      let fullDate =
        event.getFullYear() +
        "-" +
        event.getMonth() +
        "-" +
        event.getDate() +
        " " +
        event.getHours() +
        ":" +
        event.getMinutes() +
        ":" +
        event.getSeconds();
      setValues((prevState) => {
        return {
          ...prevState,
          [parameter]: event,
          [parameter + "Clean"]: fullDate,
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [parameter]: event.target.value,
        };
      });
    }
  };

  const fetchFinancialReport = async () => {
    let startDate = gregorianToJalaliDate(values.paymentStartDate);
    let endDate = gregorianToJalaliDate(values.paymentEndDate);
    await axios
      .get(
        `accounts/paid_amount_report/?start_date=${startDate}&end_date=${endDate}`
      )
      .then((res) => {
        // ToDo: generate report file and download
        const sumTextContent = `تعداد کل ثبت‌نام‌ها: ${res.data.data.total_number}\nمبلغ کل واریزی: ${res.data.data.total_amount}\nمبلغ کل کارمزد: ${res.data.data.total_fee}\nسود کل: ${res.data.data.total_profit}\n\n`;
        const fTextContent = `فول بادی:\nتعداد ثبت‌نام‌ها: ${res.data.data.packages_info[0].total_number}\nمبلغ واریزی: ${res.data.data.packages_info[0].total_amount}\nمبلغ کارمزد: ${res.data.data.packages_info[0].total_fee}\nسود: ${res.data.data.packages_info[0].total_profit}\n\n`;
        const aTextContent = `هوازی:\nتعداد ثبت‌نام‌ها: ${res.data.data.packages_info[1].total_number}\nمبلغ واریزی: ${res.data.data.packages_info[1].total_amount}\nمبلغ کارمزد: ${res.data.data.packages_info[1].total_fee}\nسود: ${res.data.data.packages_info[1].total_profit}\n\n`;
        // const cTextContent = `اصلاحی:\nتعداد ثبت‌نام‌ها: ${res.data.data.packages_info[2].total_number}\nمبلغ واریزی: ${res.data.data.packages_info[2].total_amount}\nمبلغ کارمزد: ${res.data.data.packages_info[2].total_fee}\nسود: ${res.data.data.packages_info[2].total_profit}\n\n`;
        const faTextContent = `فول بادی + هوازی:\nتعداد ثبت‌نام‌ها: ${res.data.data.packages_info[3].total_number}\nمبلغ واریزی: ${res.data.data.packages_info[3].total_amount}\nمبلغ کارمزد: ${res.data.data.packages_info[3].total_fee}\nسود: ${res.data.data.packages_info[3].total_profit}\n\n`;
        // const fcTextContent = `فول بادی + اصلاحی:\nتعداد ثبت‌نام‌ها: ${res.data.data.packages_info[4].total_number}\nمبلغ واریزی: ${res.data.data.packages_info[4].total_amount}\nمبلغ کارمزد: ${res.data.data.packages_info[4].total_fee}\nسود: ${res.data.data.packages_info[4].total_profit}\n\n`;
        // const acTextContent = `هوازی + اصلاحی:\nتعداد ثبت‌نام‌ها: ${res.data.data.packages_info[5].total_number}\nمبلغ واریزی: ${res.data.data.packages_info[5].total_amount}\nمبلغ کارمزد: ${res.data.data.packages_info[5].total_fee}\nسود: ${res.data.data.packages_info[5].total_profit}\n\n`;
        // const facTextContent = `فول بادی + هوازی + اصلاحی:\nتعداد ثبت‌نام‌ها: ${res.data.data.packages_info[6].total_number}\nمبلغ واریزی: ${res.data.data.packages_info[6].total_amount}\nمبلغ کارمزد: ${res.data.data.packages_info[6].total_fee}\nسود: ${res.data.data.packages_info[6].total_profit}\n\n`;
        const textContent =
          sumTextContent +
          fTextContent +
          aTextContent +
          // cTextContent +
          faTextContent;
        // +
        // fcTextContent +
        // acTextContent +
        // facTextContent;

        const blob = new Blob([textContent], { type: "text/plain" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "financial.txt");
        document.body.appendChild(link);
        link.click();

        // Cleanup
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }, 0);
      })
      .catch((err) => {});
  };

  const fetchUsersReport = async () => {
    if (values.newUserData) {
      let startDate = gregorianToJalaliDate(values.newUsersStartDate);
      let endDate = gregorianToJalaliDate(values.newUsersEndDate);
      await axios
        .get(
          `accounts/joined_users_report/?s_date=${startDate}&e_date=${endDate}`
        )
        .then((res) => {
          // ToDo: generate report file and download
          console.log(res.data.data);

          const fullbodyData = [];
          const aerobicData = [];
          // const correctiveData = [];

          // Header row
          fullbodyData.push(["شماره تلفن", "آیدی"]);
          aerobicData.push(["شماره تلفن", "آیدی"]);
          // correctiveData.push(["شماره تلفن", "آیدی"]);

          // Data rows
          res.data.data.forEach((item) => {
            item.package.forEach((p) => {
              const categoryName = p.category_name;
              const phoneNumber = item.user.phone_number;
              const instagramId = item.user.instagram_id;

              if (categoryName === "فول‌بادی") {
                fullbodyData.push([phoneNumber, instagramId]);
              } else if (categoryName === "هوازی") {
                aerobicData.push([phoneNumber, instagramId]);
              }
              //  else if (categoryName === "اصلاحی") {
              //   correctiveData.push([phoneNumber, instagramId]);
              // }
            });
          });

          const wb = XLSX.utils.book_new();
          const fws = XLSX.utils.aoa_to_sheet(fullbodyData);
          const aws = XLSX.utils.aoa_to_sheet(aerobicData);
          // const cws = XLSX.utils.aoa_to_sheet(correctiveData);

          // Setting column widths
          const wscols = [
            { wch: 17 }, // Width for the first column (شماره تلفن)
            { wch: 15 }, // Width for the second column (آیدی)
          ];
          fws["!cols"] = wscols;
          aws["!cols"] = wscols;
          // cws["!cols"] = wscols;

          XLSX.utils.book_append_sheet(wb, fws, "فول‌بادی");
          XLSX.utils.book_append_sheet(wb, aws, "هوازی");
          // XLSX.utils.book_append_sheet(wb, cws, "اصلاحی");

          const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

          const s2ab = (s) => {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
            return buf;
          };

          const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream",
          });
          const url = URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          let name = "";
          if (startDate !== endDate) {
            name = `${startDate}_${endDate}.xlsx`;
          } else {
            name = `${startDate}.xlsx`;
          }
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();

          // Cleanup
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
          }, 0);
        })
        .catch((err) => {});
    }
  };

  const fetchExpiredReport = async () => {
    await axios
      .get(
        `accounts/expired_users_report/?package=${values.expiredUsersPackage}`
      )
      .then((res) => {
        // ToDo: generate report file and download
      })
      .catch((err) => {});
  };

  const fetchInstaIdUpdatesReport = async () => {
    let startDate = gregorianToJalaliDate(values.instaIdUpdateStartDate);
    let endDate = gregorianToJalaliDate(values.instaIdUpdateEndDate);
    await axios
      .get(
        `accounts/instagram_update_users_report/?start_date=${startDate}&end_date=${endDate}`
      )
      .then((res) => {
        console.log(res.data);

        const usersData = [];

        // Header row
        usersData.push([
          "شماره تلفن",
          "نام",
          "نام خانوادگی",
          "آیدی قبلی",
          "آیدی جدید",
          "زمان آخرین تغییر",
          "",
        ]);

        // Data rows
        res.data.data.forEach((item) => {
          const phoneNumber = item.user.phone_number;
          const name = item.user.name;
          const lastName = item.user.last_name;
          const instagramId = item.user.instagram_id;
          const previousInstagramId = item.user.previous_instagram_id;
          const instagramLastUpdate = item.user.instagram_last_update;

          usersData.push([
            phoneNumber,
            name,
            lastName,
            instagramId,
            previousInstagramId,
            instagramLastUpdate,
          ]);
        });

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(usersData);

        // Setting column widths
        const wscols = [
          { wch: 17 }, // Width for the first column (شماره تلفن)
          { wch: 15 }, // Width for the second column (آیدی)
        ];
        ws["!cols"] = wscols;

        XLSX.utils.book_append_sheet(wb, ws, "آیدی‌ها");

        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

        const s2ab = (s) => {
          const buf = new ArrayBuffer(s.length);
          const view = new Uint8Array(buf);
          for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
          return buf;
        };

        const blob = new Blob([s2ab(wbout)], {
          type: "application/octet-stream",
        });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "id_updates_report.xlsx");
        document.body.appendChild(link);
        link.click();

        // Cleanup
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }, 0);
      })
      .catch((err) => {});
  };

  const handleFetchCategories = async () => {
    await axiosPublic("packs/categories_list/")
      .then((res) => {
        if (res.status === 200) {
          setValues((prevState) => {
            return { ...prevState, packTypes: res.data.data };
          });
        }
      })
      .catch((err) => {});
  };

  const handleCreateCategory = async () => {
    let data = {
      name: values.catName,
      en_name: values.catEnName,
      goal: values.catGoal,
      equipments: values.catEquipments,
    };

    await axios
      .post("packs/category/", data)
      .then((res) => {
        if (res.status === 201) {
          setValues((prevState) => {
            return {
              ...prevState,
              name: "",
              en_name: "",
              goal: "",
              equipments: "",
            };
          });
          setMuiAlert({
            show: true,
            type: "success",
            message: "دسته‌بندی جدید ایجاد شد",
          });
        }
      })
      .catch((err) => {
        setMuiAlert({
          show: true,
          type: "error",
          message: "مشکلی وجود دارد ",
        });
      });
  };

  const handleCreatePackage = async () => {
    let data = {
      name: values.packName,
      en_name: values.packEnName,
      price: values.packPrice,
      start_date: gregorianToJalaliDate(values.packStartDate),
      end_date: gregorianToJalaliDate(values.packEndDate),
      purchase_start_time: gregorianToJalaliDateTime(
        values.packRegisterStartDate
      ),
      purchase_end_time: gregorianToJalaliDateTime(values.packRegisterEndDate),
      recharge_start_time: gregorianToJalaliDateTime(
        values.packRechargeStartDate
      ),
      category: values.packSelectedPackType,
      instagram_id: values.instagramId,
    };

    await axios
      .post("packs/pack/", data)
      .then((res) => {
        if (res.status === 201) {
          setValues((prevState) => {
            return {
              ...prevState,
              packName: "",
              packEnName: "",
              packPrice: "",
              packStartDate: null,
              packEndDate: null,
              packRegisterStartDate: null,
              packRegisterEndDate: null,
              packRechargeStartDate: null,
              packSelectedPackType: "",
            };
          });
          setMuiAlert({
            show: true,
            type: "success",
            message: "دوره‌ی جدید ایجاد شد",
          });
        }
      })
      .catch((err) => {
        setMuiAlert({
          show: true,
          type: "error",
          message: "مشکلی وجود دارد ",
        });
      });
  };

  const handleFetchLatestPacks = async () => {
    await axiosPublic("packs/active_pack_essential/")
      .then((res) => {
        if (res.status === 200) {
          setValues((prevState) => {
            return { ...prevState, latestPacks: res.data.data };
          });
        }
      })
      .catch((err) => {});
  };

  const handleAddLiveUrl = async () => {
    let data = {
      package: values.liveSelectedPack,
      date: gregorianToJalaliDate(values.liveDate),
      url: values.liveUrl,
    };

    await axios
      .post("packs/live/", data)
      .then((res) => {
        if (res.status === 201) {
          setValues((prevState) => {
            return {
              ...prevState,
              liveSelectedPack: "",
              liveDate: null,
              liveUrl: "",
            };
          });
          setMuiAlert({
            show: true,
            type: "success",
            message: "لایو جدید اضافه شد",
          });
        }
      })
      .catch((err) => {
        setMuiAlert({
          show: true,
          type: "error",
          message: "مشکلی وجود دارد",
        });
      });
  };

  const handleLogout = () => {
    setAnchorEl(null);

    axios
      .post("/accounts/logout/")
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("token");
          navigate("/");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          navigate("/");
        }
      });
  };

  useEffect(() => {
    handleFetchCategories();
    handleFetchLatestPacks();
  }, []);

  return (
    <Grid2 container xs={12} justifyContent={"center"} sx={styles.cntnr}>
      <Grid2 container xs={12} sx={styles.widthLmt}>
        <Grid2 container xs={12} justifyContent={"flex-end"}>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClickMenu}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Link to="/" css={styles.link}>
              <MenuItem onClick={handleCloseMenu}>
                <ListItemIcon>
                  <HomeIcon fontSize="small" sx={styles.menuItems} />
                </ListItemIcon>
                <ListItemText>صفحه‌ی اصلی</ListItemText>
              </MenuItem>
            </Link>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" sx={styles.menuItems} />
              </ListItemIcon>
              <ListItemText sx={styles.menuItems}>خروج</ListItemText>
            </MenuItem>
          </Menu>
        </Grid2>
        <Grid2 container xs={12} sx={styles.partCntnr}>
          <Grid2
            container
            alignContent={"center"}
            xs={12}
            sx={styles.contentRowsCntnr}
          >
            <Grid2 container alignContent={"center"}>
              <AssignmentOutlinedIcon sx={styles.icon} />
            </Grid2>
            <Grid2 sx={styles.title}>گزارشات</Grid2>
          </Grid2>
          <Grid2 xs={12} container rowGap={4}>
            <Grid2
              xs={12}
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              rowGap={2}
              columnSpacing={2}
            >
              <Grid2 xs={12} sx={styles.reportsTitles}>
                گزارش مالی
              </Grid2>
              <Grid2 xs={12} md={4}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFnsJalali}
                  localeText={{
                    cancelButtonLabel: "خروج",
                    okButtonLabel: "ذخیره",
                  }}
                >
                  <MobileDatePicker
                    label={"تاریخ شروع"}
                    value={values.paymentStartDate}
                    sx={styles.textFld}
                    onChange={handleChange("paymentStartDate", "date")}
                  />
                </LocalizationProvider>
              </Grid2>
              <Grid2 xs={12} md={4}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFnsJalali}
                  localeText={{
                    cancelButtonLabel: "خروج",
                    okButtonLabel: "ذخیره",
                  }}
                >
                  <MobileDatePicker
                    label={"تاریخ پایان"}
                    value={values.paymentEndDate}
                    sx={styles.textFld}
                    onChange={handleChange("paymentEndDate", "date")}
                  />
                </LocalizationProvider>
              </Grid2>
              <Grid2 xs={12} md={4}>
                <Button
                  variant="contained"
                  sx={styles.reportBtn}
                  startIcon={<AttachMoneyIcon />}
                  onClick={fetchFinancialReport}
                >
                  دریافت گزارش مالی
                </Button>
              </Grid2>
            </Grid2>

            <Grid2
              xs={12}
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              rowGap={2}
              columnSpacing={2}
            >
              <Grid2 xs={12} sx={styles.reportsTitles}>
                گزارش ثبت‌نامی‌ها
              </Grid2>
              <Grid2 xs={12} md={4}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFnsJalali}
                  localeText={{
                    cancelButtonLabel: "خروج",
                    okButtonLabel: "ذخیره",
                  }}
                >
                  <MobileDatePicker
                    label={"تاریخ شروع"}
                    value={values.newUsersStartDate}
                    sx={styles.textFld}
                    onChange={handleChange("newUsersStartDate", "date")}
                  />
                </LocalizationProvider>
              </Grid2>
              <Grid2 xs={12} md={4}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFnsJalali}
                  localeText={{
                    cancelButtonLabel: "خروج",
                    okButtonLabel: "ذخیره",
                  }}
                >
                  <MobileDatePicker
                    label={"تاریخ پایان"}
                    value={values.newUsersEndDate}
                    sx={styles.textFld}
                    onChange={handleChange("newUsersEndDate", "date")}
                  />
                </LocalizationProvider>
              </Grid2>
              <Grid2 xs={12} md={4}>
                <Button
                  variant="contained"
                  sx={styles.reportBtn}
                  startIcon={<PeopleIcon />}
                  onClick={fetchUsersReport}
                >
                  دریافت لیست آیدی‌ها
                </Button>
              </Grid2>
            </Grid2>
            <Grid2
              xs={12}
              container
              alignItems={"center"}
              rowGap={2}
              columnSpacing={2}
            >
              <Grid2 xs={12} sx={styles.reportsTitles}>
                گزارش حذفی‌ها
              </Grid2>
              <Grid2 xs={12} md={8}>
                <TextField
                  select
                  label={"دوره"}
                  value={values.expiredUsersPackage}
                  sx={styles.textFld}
                  onChange={handleChange("expiredUsersPackage", "select")}
                >
                  {values.packTypes.map((option) => (
                    <MenuItem key={option.id} value={option.en_name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid2>
              <Grid2 xs={12} md={4}>
                <Button
                  variant="contained"
                  sx={styles.reportBtn}
                  startIcon={<PersonRemoveIcon />}
                  onClick={fetchExpiredReport}
                >
                  دریافت لیست حذفی‌ها
                </Button>
              </Grid2>
            </Grid2>
            <Grid2
              xs={12}
              container
              alignItems={"center"}
              rowGap={2}
              columnSpacing={2}
            >
              <Grid2 xs={12} sx={styles.reportsTitles}>
                گزارش آیدی‌های تغییر یافته
              </Grid2>
              <Grid2 xs={12} md={4}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFnsJalali}
                  localeText={{
                    cancelButtonLabel: "خروج",
                    okButtonLabel: "ذخیره",
                  }}
                >
                  <MobileDatePicker
                    label={"تاریخ شروع"}
                    value={values.instaIdUpdateStartDate}
                    sx={styles.textFld}
                    onChange={handleChange("instaIdUpdateStartDate", "date")}
                  />
                </LocalizationProvider>
              </Grid2>
              <Grid2 xs={12} md={4}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFnsJalali}
                  localeText={{
                    cancelButtonLabel: "خروج",
                    okButtonLabel: "ذخیره",
                  }}
                >
                  <MobileDatePicker
                    label={"تاریخ پایان"}
                    value={values.instaIdUpdateEndDate}
                    sx={styles.textFld}
                    onChange={handleChange("instaIdUpdateEndDate", "date")}
                  />
                </LocalizationProvider>
              </Grid2>
              <Grid2 xs={12} md={4}>
                <Button
                  variant="contained"
                  sx={styles.reportBtn}
                  startIcon={<AttachMoneyIcon />}
                  onClick={fetchInstaIdUpdatesReport}
                >
                  دریافت گزارش آیدی‌ها
                </Button>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 container xs={12} sx={styles.partCntnr}>
          <Grid2
            container
            alignContent={"center"}
            xs={12}
            sx={styles.contentRowsCntnr}
          >
            <Grid2 container alignContent={"center"}>
              <AddCircleOutlineIcon sx={styles.icon} />
            </Grid2>
            <Grid2 sx={styles.title}>دوره‌ی جدید</Grid2>
          </Grid2>
          <Grid2
            container
            alignContent={"center"}
            xs={12}
            sx={styles.contentRowsCntnr}
          >
            برای ایجاد دوره‌ی جدید برای هر ماه، از این قسمت اقدام کنید.
          </Grid2>
          <Grid2
            container
            xs={12}
            spacing={{ xs: 2, md: 3 }}
            alignItems="flex-start"
            sx={styles.formCntnr}
          >
            {newPackageFields.map((item) => {
              return (
                <Grid2 xs={12} md={6}>
                  {item.type === "text" ? (
                    <TextField
                      variant="outlined"
                      label={item.title}
                      placeholder={item.placeHolder && item.placeHolder}
                      value={values[item.enTitle]}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {item.adornment}
                          </InputAdornment>
                        ),
                      }}
                      sx={styles.textFld}
                      onChange={handleChange(item.enTitle, item.type)}
                    />
                  ) : item.type === "date" ? (
                    <LocalizationProvider
                      dateAdapter={AdapterDateFnsJalali}
                      localeText={{
                        cancelButtonLabel: "خروج",
                        okButtonLabel: "ذخیره",
                      }}
                    >
                      <MobileDatePicker
                        label={item.title}
                        value={values[item.enTitle]}
                        sx={styles.textFld}
                        onChange={handleChange(item.enTitle, item.type)}
                      />
                    </LocalizationProvider>
                  ) : item.type === "dateTime" ? (
                    <LocalizationProvider
                      dateAdapter={AdapterDateFnsJalali}
                      localeText={{
                        cancelButtonLabel: "خروج",
                        okButtonLabel: "ذخیره",
                      }}
                    >
                      <MobileDateTimePicker
                        label={item.title}
                        value={values[item.enTitle]}
                        sx={styles.textFld}
                        onChange={handleChange(item.enTitle, item.type)}
                      />
                    </LocalizationProvider>
                  ) : (
                    item.type === "select" && (
                      <TextField
                        select
                        label={item.title}
                        value={values[item.enTitle]}
                        sx={styles.textFld}
                        onChange={handleChange(item.enTitle, item.type)}
                      >
                        {values.packTypes.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  )}
                </Grid2>
              );
            })}
          </Grid2>
          <Grid2
            container
            xs={12}
            justifyContent={"flex-end"}
            sx={styles.buttonCntnr}
          >
            <Button
              variant="contained"
              sx={styles.button}
              onClick={handleCreatePackage}
            >
              ایجاد دوره
            </Button>
          </Grid2>
        </Grid2>

        <Grid2 container xs={12} sx={styles.partCntnr}>
          <Grid2
            container
            alignContent={"center"}
            xs={12}
            sx={styles.contentRowsCntnr}
          >
            <Grid2 container alignContent={"center"}>
              <CategoryOutlinedIcon sx={styles.icon} />
            </Grid2>
            <Grid2 sx={styles.title}>دسته‌بندی جدید</Grid2>
          </Grid2>
          <Grid2
            container
            alignContent={"center"}
            xs={12}
            sx={styles.contentRowsCntnr}
          >
            برای ایجاد دسته‌بندی یا نوع جدیدی از دوره‌، از این قسمت اقدام کنید.
          </Grid2>
          <Grid2
            container
            xs={12}
            spacing={{ xs: 2, md: 3 }}
            alignItems="flex-start"
            sx={styles.formCntnr}
          >
            {newCategoryFields.map((item) => {
              return (
                <Grid2 xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    label={item.title}
                    value={values[item.enTitle]}
                    placeholder={item.placeHolder && item.placeHolder}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {item.adornment}
                        </InputAdornment>
                      ),
                    }}
                    sx={styles.textFld}
                    onChange={handleChange(item.enTitle, item.type)}
                  />
                </Grid2>
              );
            })}
          </Grid2>
          <Grid2
            container
            xs={12}
            justifyContent={"flex-end"}
            sx={styles.buttonCntnr}
          >
            <Button
              variant="contained"
              sx={styles.button}
              onClick={handleCreateCategory}
            >
              ایجاد دسته‌بندی
            </Button>
          </Grid2>
        </Grid2>

        <Grid2 container xs={12} sx={styles.partCntnr}>
          <Grid2
            container
            alignContent={"center"}
            xs={12}
            sx={styles.contentRowsCntnr}
          >
            <Grid2 container alignContent={"center"}>
              <CategoryOutlinedIcon sx={styles.icon} />
            </Grid2>
            <Grid2 sx={styles.title}>آرشیو لایوها</Grid2>
          </Grid2>
          <Grid2
            container
            alignContent={"center"}
            xs={12}
            sx={styles.contentRowsCntnr}
          >
            برای افزودن لینک ویدیو لایو جلسه جدید، از این قسمت اقدام کنید.
          </Grid2>
          <Grid2
            container
            xs={12}
            spacing={{ xs: 2, md: 3 }}
            alignItems="flex-start"
            sx={styles.formCntnr}
          >
            {liveFields.map((item) => {
              return (
                <Grid2 xs={12} md={item.type === "text" ? 12 : 6}>
                  {item.type === "text" ? (
                    <TextField
                      variant="outlined"
                      multiline
                      label={item.title}
                      placeholder={item.placeHolder && item.placeHolder}
                      value={values[item.enTitle]}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {item.adornment}
                          </InputAdornment>
                        ),
                      }}
                      sx={styles.textFld}
                      onChange={handleChange(item.enTitle, item.type)}
                    />
                  ) : item.type === "date" ? (
                    <LocalizationProvider
                      dateAdapter={AdapterDateFnsJalali}
                      localeText={{
                        cancelButtonLabel: "خروج",
                        okButtonLabel: "ذخیره",
                      }}
                    >
                      <MobileDatePicker
                        label={item.title}
                        value={values[item.enTitle]}
                        sx={styles.textFld}
                        onChange={handleChange(item.enTitle, item.type)}
                      />
                    </LocalizationProvider>
                  ) : (
                    item.type === "select" && (
                      <TextField
                        select
                        label={item.title}
                        value={values[item.enTitle]}
                        sx={styles.textFld}
                        onChange={handleChange(item.enTitle, item.type)}
                      >
                        {values.latestPacks.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  )}
                </Grid2>
              );
            })}
          </Grid2>
          <Grid2
            container
            xs={12}
            justifyContent={"flex-end"}
            sx={styles.buttonCntnr}
          >
            <Button
              variant="contained"
              sx={styles.button}
              onClick={handleAddLiveUrl}
            >
              ایجاد دسته‌بندی
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
      <Snackbar
        open={muiAlert.show}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseAlert} severity={muiAlert.type}>
          {muiAlert.message}
        </Alert>
      </Snackbar>
    </Grid2>
  );
}
